import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import "./receiptForm.css";
import NavBar from "../../components/navBar/navBar.js";

function ReceiptForm() {
    const [formData, setFormData] = useState({
        name: "",
        startLocation: "",
        destination: "",
        rideAmount: "",
        vatPercentage: 10,
        email: "",
        paymentMethod: "card",
        date: "",
        time: "",
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const convertToDecimal = (value) => {
        return parseFloat(value.replace(",", "."));
    };

    const calculateBasePrice = () => {
        const totalAmount = convertToDecimal(formData.rideAmount);
        const basePrice = totalAmount / (1 + formData.vatPercentage / 100);
        return basePrice.toFixed(2);
    };

    const calculateVAT = () => {
        const totalAmount = convertToDecimal(formData.rideAmount);
        const basePrice = parseFloat(calculateBasePrice());
        const vatAmount = totalAmount - basePrice;
        return vatAmount.toFixed(2);
    };

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);

        const basePrice = calculateBasePrice();
        const vatAmount = calculateVAT();

        const formDataWithCalculations = {
            name: formData.name,
            startLocation: formData.startLocation,
            destination: formData.destination,
            rideAmount: formData.rideAmount,
            vatPercentage: formData.vatPercentage,
            vatAmount: vatAmount,
            basePrice: basePrice,
            totalAmount: formData.rideAmount,
            email: formData.email,
            paymentMethod: formData.paymentMethod,
            date: formData.date,
            time: formData.time,
        };

        // Send data to the backend to process and send the email
        fetch("https://vipkyyti-api.onrender.com/send-receipt", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formDataWithCalculations),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to send email.");
                }
                return response.json();  // Parse JSON response here
            })
            .then((data) => {
                console.log("Email sent successfully:", data);
                alert("Receipt sent successfully!");
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error sending email:", error);
                alert("Failed to send receipt.");
                setLoading(false);
            });
    };

    return (
        <div className="receiptFormBody">
            <NavBar />
            <form className="receiptForm" onSubmit={sendEmail}>
                <div>
                    <label>Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Start Location:</label>
                    <input
                        type="text"
                        name="startLocation"
                        value={formData.startLocation}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Destination:</label>
                    <input
                        type="text"
                        name="destination"
                        value={formData.destination}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Total Ride Amount (€):</label>
                    <input
                        type="text"
                        name="rideAmount"
                        value={formData.rideAmount}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>VAT Percentage (%):</label>
                    <input
                        type="number"
                        name="vatPercentage"
                        value={formData.vatPercentage}
                        onChange={handleChange}
                        required
                        min="0"
                    />
                </div>
                <div>
                    <label>Email of Customer:</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Date:</label>
                    <input
                        type="date"
                        name="date"
                        value={formData.date}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Time:</label>
                    <input
                        type="time"
                        name="time"
                        value={formData.time}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Payment Method:</label>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="paymentMethod"
                                value="card"
                                checked={formData.paymentMethod === "card"}
                                onChange={handleChange}
                            />
                            Card
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="paymentMethod"
                                value="cash"
                                checked={formData.paymentMethod === "cash"}
                                onChange={handleChange}
                            />
                            Cash
                        </label>
                    </div>
                </div>
                <button type="submit" disabled={loading}>
                    {loading ? "Sending..." : "Send Receipt"}
                </button>

                {loading && (
                    <div className="loaderOverlay">
                        <div className="loaderContainer">
                            <TailSpin
                                height="80"
                                width="80"
                                color="#3498db"
                                ariaLabel="loading"
                            />
                            <p>Sending receipt...</p>
                        </div>
                    </div>
                )}
            </form>
        </div>
    );
}

export default ReceiptForm;
